<template>
  <div class="bbsMall_Me">
    <img
      class="bar_goReturn"
      src="@image/bbsMall/kk_icon/Vector 20_icon.png"
      @click="$router.go(-1)"
    />

    <div class="me_info flex-container">
      <div class="info flex1">
        <h2>{{ author_info.author_name }}</h2>
        <p>{{ author_info.signature }}</p>
      </div>
      <img :src="author_info.author_avatar" class="headimg" />
    </div>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <oInfoList :list="goodsArticleData" :finished="finished"></oInfoList>
    </van-list>
  </div>
</template>

<script>
import { List } from "vant";
import oInfoList from "../components/infoList/";
import { get } from "@get/http.js";
export default {
  methods: {
    async onLoad() {
      let data = await get("/GoodsArticle/author", {
        params: {
          pageSize: this.pageSize,
          page: this.page,
          author_id: this.$route.query?.author_id,
        },
      });

      this.author_info = data.author_info;
      this.page++;
      this.goodsArticleData.push(...data.goodsArticleData.data);
      // 加载状态结束
      this.loading = false;
      if (data.goodsArticleData.data.length != this.pageSize) {
        this.finished = true;
      }
    },
  },
  data() {
    return {
      author_info: {},
      goodsArticleData: [],
      pageSize: 20,
      page: 1,
      loading: false,
      finished: false,
    };
  },
  components: {
    oInfoList,
    vanList: List,
  },
  created() {},
};
</script>
<style lang="scss">
.van-list__finished-text {
  display: none;
}
</style>
<style lang="scss" scoped>
@import "~@css/mixin.scss";
.bar_goReturn {
  width: rem(15);
  height: rem(29);
  position: absolute;
  left: rem(32);
  top: rem(32);
}
.bbsMall_Me {
  position: relative;
  background: url("~@image/bbsMall/me_bg.png") no-repeat;
  background-size: rem(750) rem(303);
  width: rem(750);
  margin: 0 auto;
  .me_info {
    height: rem(303);
    margin: 0 rem(44) rem(20);

    align-items: center;
    .info {
      h2 {
        font-size: rem(42);
        line-height: rem(60);
        font-weight: bold;
        text-align: justify;
        color: #333333;
        margin-bottom: rem(10);
      }
      p {
        margin-right: rem(40);
        font-size: rem(28);
        line-height: rem(40);
        text-align: justify;
        color: #999999;
      }
    }
    .headimg {
      width: rem(140);
      height: rem(140);
      display: block;
      border-radius: 50%;
    }
  }
}
</style>
